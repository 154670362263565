import { group } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
//import { HoudiniPhotospheresService, HoudiniModalsService } from "houdini-photospheres/dist/houdini-photospheres";
import { HoudiniPhotospheresService, HoudiniModalsService } from "houdini-photospheres";
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  // Controls if this component is visible and interactive
  public enabled: boolean = false;

  // Data for populating the carousel
  public carouselData: any = null;

  // Carousel groups
  public carouselGroups: any[] = [];

  // Current carousel group
  public currentGroup: any = null;

  // Carousel group index
  public groupIndex: number = -1;

  // Iterable group index numbers
  public groupNumbers: number[]= [];

  // Slides are visible?
  public slidesVisible: boolean = false;

  // Slides enabled / on
  public slidesEnabled: number[]= [];

  // setTimeout()'s for slide animations
  public slideTimeouts: any[]= [];

  // Mobile layout?
  public isMobile: boolean = false;

  // Timeout for toggling on/off; prevent carousel flashing on before event center has completely loaded
  public enabledTimeout: any = null;

  constructor(
    private modalsSvc: HoudiniModalsService,
    private utilsSvc: UtilsService
  ) { }

  ngOnInit(): void {
    // Show carousel when viewing event center photosphere
    this.utilsSvc.currentPhotosphere.subscribe((data) => {
      if (data === null) {
        return;
      }
      if (data?.id === "event-center") {
        if (this.enabledTimeout) {
          clearTimeout(this.enabledTimeout);
        }
        this.enabledTimeout = setTimeout(() => {
          this.enabled = true;
        }, 2000);
      } else {
        this.enabled = false;
      }
    });
    // Listen for scene data, and grab carousel 
    this.utilsSvc.dataSubject.subscribe((data) => {
      if (data === null || !data?.modals) {
        return;
      }
      this.carouselData = data.modals.find((modal: any) => modal?.id === "event-center-gallery");
      this.buildCarouselGroups();
    });
    // Change certain layout elements depending on screen size
    this.utilsSvc.isMobile.subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
  }

  /**
   * Chunk gallery items into groups
   * - Items within the group
   * - Background image associated with the group
   */
  public buildCarouselGroups() {
    const groupSize: number = this.carouselData?.customData.groupSize;
    const gallery: any[] = this.carouselData?.gallery;
    this.carouselGroups = [];
    let i, j, k = 0, bgname = "";
    for (i = 0, j = gallery.length; i < j; i += groupSize) {
      k++;
      bgname = "background" + k;
      this.carouselGroups.push({
        "background": this.carouselData?.customData[bgname],
        "items": gallery.slice(i, i + groupSize)
      });
    }
    this.groupNumbers = Array(this.carouselGroups.length).fill(0).map((x, i) => i);
    this.gotoGroup(0);
  }

  public nextGroup() {
    this.gotoGroup(this.groupIndex + 1);
  }

  public prevGroup() {
    this.gotoGroup(this.groupIndex - 1);
  }

  public gotoGroup(index: number) {
    let i = index;
    if (this.carouselGroups.length < 1) {
      i = 0;
    } else if (i >= this.carouselGroups.length) {
      i = 0;
    } else if (i < 0) {
      i = this.carouselGroups.length - 1;
    }
    this.groupIndex = i;
    this.currentGroup = this.carouselGroups[i];
    if (this.currentGroup?.background) {
      this.utilsSvc.backgroundImageSubject.next(this.currentGroup.background);
    }
    this.animateInSlides();
  }

  public animateInSlides() {
    this.slideTimeouts.forEach((to) => {
      clearTimeout(to);
    });
    this.slideTimeouts = [];
    this.slidesVisible = false;
    this.slidesEnabled = [];
    const to = setTimeout(() => {
      this.slidesVisible = true;
      for (let i = 0; i < this.currentGroup?.items.length; i++) {
        this.animInSlide(i);
      }
    }, 1);
    this.slideTimeouts.push(to);
  }

  public animInSlide(index: number) {
    const to = setTimeout(() => {
      this.slidesEnabled.push(index);
    }, 100 * (index + 1));
    this.slideTimeouts.push(to);
  }

  public itemClick(item: any) {
    console.log(item, "itemClick item");
    if (item?.type === "link") {
      if (item?.assetUrl) {
        window.open(item.assetUrl, "_blank");
      }
    } else if (item.type === "youtube") {
      const modal: any = {
        "id": "gallery-item-" + item.id,
        "modalType": "video",
        "src": item?.assetUrl,
        "poster": item?.thumbnailUrl,
        "autoplay": true,
        "title": item.title,
        "description": "",
        "youtube": true,
        "showMeta": item?.showMeta
      }
      this.modalsSvc.setActiveModalByData(modal);
    } else {
      const modal: any = {
        "id": "gallery-item-" + item?.id,
        "modalType": "video",
        "src": item?.assetUrl,
        "poster": item?.thumbnailUrl,
        "autoplay": true,
        "title": item?.title,
        "description": ""
      }
      this.modalsSvc.setActiveModalByData(modal);
    }
  }

}
