<div
    id="basf_carousel"
    class="carousel"
    [ngClass]="[ enabled && groupNumbers.length && !isMobile ? 'on' : '' ]"
>
    <div class="carousel-inner">
        <div class="slider-spacer">
            <div
                *ngFor="let i of groupNumbers"
                class="slide"
                [class]="'slide-' + (i + 1)"
            >
                <div class="img">
                    <img [src]="'/assets/images/slide-' + (i + 1) + '-bg.png'" alt="">
                </div>
            </div>
        </div>
        <div class="slider" *ngIf="currentGroup && slidesVisible">
            <div
                *ngFor="let item of currentGroup?.items, let i = index"
                class="slide"
                [class]="'slide-' + (i + 1)"
                [ngClass]="{ 'on': slidesEnabled.includes(i) }"
            >
                <div class="img">
                    <img [src]="item?.customData.carouselImage" alt="item?.title">
                </div>
                <div class="hover">
                    <img [src]="'/assets/images/slide-' + (i + 1) + '-bg.png'" alt="">
                </div>
                <button
                    type="button"
                    class="over"
                    (click)="this.itemClick(item)"
                >
                    <span class="icon-container">
                        <span class="photosphere-flag disc">
                            <span class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37">
                                <polygon points="37 16 37 21 21 21 21 37 16 37 16 21 0 21 0 16 16 16 16 0 21 0 21 16 37 16"></polygon>
                            </svg>
                            </span>
                        </span>
                    </span>
                    <span class="label" [innerHTML]="item?.title"></span>
                </button>
            </div>
        </div>
        <div class="arrows" *ngIf="carouselGroups.length > 1">
            <button type="button" class="carousel-arrow left" (click)="prevGroup()"></button>
            <button type="button" class="carousel-arrow right" (click)="nextGroup()"></button>
        </div>
        <div class="dots" *ngIf="carouselGroups.length > 1">
            <button
                *ngFor="let group of carouselGroups, let i = index"
                type="button"
                class="carousel-dot"
                [class]="'dot-' + (i + 1)"
                [ngClass]="{ active: i === groupIndex }"
                (click)="gotoGroup(i)"
            ></button>
        </div>
    </div>
</div>
<div class="event-center-gallery" *ngIf="enabled && carouselData && isMobile">
  <houdini-modal-gallery [data]="carouselData"></houdini-modal-gallery>
</div>