import { Component, Input } from '@angular/core';
//import { EditorField, ModalComponent } from 'houdini-photospheres/dist/houdini-photospheres';
import { EditorField, ModalComponent } from 'houdini-photospheres';

@Component({
  template: `
    <div>
      <h1>Gallery modal test</h1>
      <h1>{{data.title}}</h1>
    </div>
  `
})
export class BasfModalGallery implements ModalComponent {
  @Input() data: any;

  get editFields(): EditorField[] {
    return [];
  }
  
}