import { Component, OnInit, OnDestroy } from '@angular/core';
//import { HoudiniPhotospheresService } from "houdini-photospheres/dist/houdini-photospheres";
import { HoudiniPhotospheresService } from "houdini-photospheres";
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

  // Variables to control navigation layout
  public smallerLogo: boolean = true;
  public isMobile: boolean = false;
  public showWelcome: boolean = false;
  
  // Current photosphere
  public photosphere: any = null;

  // Navigation copy/content
  public navId: string = "";
  public navTitle: string = "";

  // Show/hide drawer menus
  public drawerDesktopShow: boolean = false;
  public drawerMobileShow: boolean = false;

  // Control which subnav is expanded
  public subNavExpanded: string = "";

  // Show back navigation
  public showBack: boolean = false;

  // Navigation items
  public navItems: any[] = [
    {
      id: "welcome-lobby",
      title: "Welcome Lobby",
      path: "/welcome-lobby",
      contextual: 1
    },
    {
      id: "event-center",
      title: "Event Center",
      path: "/event-center",
      contextual: 3
    },
    {
      id: "innovation-center",
      title: "Innovation Center",
      path: "/innovation-center",
      contextual: 2,
      submenu: [
        {
          id: "ic-sustainability",
          title: "Sustainability",
          path: "/innovation-center/sustainability",
          contextual: 1
        },
        {
          id: "ic-auto",
          title: "Autmotive Design",
          path: "/innovation-center/automotive-design",
          contextual: 2
        },
        {
          id: "ic-emobility",
          title: "eMobility",
          path: "/innovation-center/emobility",
          contextual: 3
        },
        {
          id: "ic-safety",
          title: "Safety & Comfort",
          path: "/innovation-center/safety-and-comfort",
          contextual: 4
        }
      ]
    },
    {
      id: "meeting-room",
      title: "Meeting Room",
      path: "/meeting-room"
    }
  ];

  // Current data for contextual navigation items 
  public contextualNav: any[]= [];

  // Model for left contextual navigation item
  public contextualLeft: any = null;

  // Model for right contextual navigation item
  public contextualRight: any = null;

  //
  constructor(
    private photosphereSvc: HoudiniPhotospheresService,
    private utilsSvc: UtilsService,
    private router: Router
  ) { }

  /**
   * Initialize navigation elements
   */
  ngOnInit(): void {
    // Collapse menu when clicking outside it
    document.addEventListener("click", this.checkOutsideClick.bind(this), false);
    document.addEventListener("touchstart", this.checkOutsideClick.bind(this), false);
    // Photoshpere changed
    this.utilsSvc.currentPhotosphere.subscribe((data) => {
      if (data === null) {
        return;
      }
      // Set current photosphere
      this.photosphere = data;
      // Detect navigation match with current photosphere
      let found = false;
      if (this.photosphere?.id) {
        const navItem = this.findNavItem(this.photosphere.id);
        if (navItem) {
          this.navId = navItem.id;
          if (navItem?.parent) {
            this.navTitle = navItem.parent.title;
          } else {
            this.navTitle = navItem.title;
          }
          found = true;
        }
      } 
      if (!found) {
        this.navId = "";
        this.navTitle = "";
      }
      // Show welcome message on home screen
      this.showWelcome = this.photosphere?.id === "home";
      // Display smaller logo when not on home scene/photosphere, or when on mobile
      if (this.isMobile || !this.showWelcome) {
        this.smallerLogo = true;
      } else {
        this.smallerLogo = this.photosphere ? this.photosphere.id !== "home" : false;
      }
      // Show "Back to Innovation Center" for IC sub-scenes
      // Use different contextual nav for Innovation Center
      if (this.photosphere?.id && ["ic-sustainability", "ic-auto", "ic-emobility", "ic-safety"].includes(this.photosphere.id)) {
        this.showBack = true;
        this.buildContextualNav(this.photosphere?.id, "innovation-center");
      } else {
        this.showBack = false;
        this.buildContextualNav(this.photosphere?.id);
      }
    });
    // Change certain layout elements depending on screen size
    this.utilsSvc.isMobile.subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
      this.smallerLogo = this.showWelcome ? isMobile : true;
    });
    // Detect when a navigation click occured from another component
    this.utilsSvc.navClicked.subscribe((clicked: boolean) => {
      if (clicked) {
        this.showWelcome = false;
        this.drawerDesktopShow = false;
        this.drawerMobileShow = false;
      }
    });
  }

  /**
   * - Cleanup custom event handlers
   */
  ngOnDestroy(): void {
    document.removeEventListener("click", this.checkOutsideClick);
    document.removeEventListener("touchstart", this.checkOutsideClick);
  }

  /**
   * Find a navigation item object
   * @param id 
   * @returns 
   */
  public findNavItem(id: string) {
    let navItem: any = null;
    for (let item of this.navItems) {
      if (item.id === id) {
        navItem = item;
        break;
      } else if (item.submenu) {
        for (let subItem of item.submenu) {
          if (subItem.id === id) {
            navItem = subItem;
            navItem.parent = item;
            break;
          }
        }
        if (navItem) {
          break;
        }
      }
    }
    return navItem;
  }

  /**
   * Return a list of contextual nav items
   */
  public getContextualNav(parent: string = ""): any[] {
    let searchItems: any[] = [];
    if (parent) {
      const parentItem = this.navItems.find((item: any) => item.id === parent);
      if (parentItem && parentItem?.submenu) {
        searchItems = parentItem?.submenu;
      }
    } else {
      searchItems = this.navItems;
    }
    if (!searchItems) {
      return searchItems;
    }
    let items: any[] = searchItems.filter((item: any) => !!item?.contextual);
    items.sort((a: any, b: any) => {
      return a?.contextual > b?.contextual ? 1 : (a?.contextual < b?.contextual ? -1 : 0);
    });
    return items;
  }

  /**
   * Build data model for contextual navigation items
   * @param parent
   */
  public buildContextualNav(id: string, parent: string = "") {
    this.contextualNav = this.getContextualNav(parent);
    const index = this.contextualNav.findIndex((item: any) => item.id === id);
    let indexLeft = -1;
    let indexRight = -1;
    if (index > -1) {
      indexLeft = index - 1;
      if (indexLeft < 0) {
        indexLeft = this.contextualNav.length - 1;
      }
      indexRight = index + 1;
      if (indexRight >= this.contextualNav.length) {
        indexRight = 0;
      }
    }
    if (indexLeft > -1) {
      this.contextualLeft = this.contextualNav[indexLeft];
    } else {
      this.contextualLeft = null;
    }
    if (indexRight > -1) {
      this.contextualRight = this.contextualNav[indexRight];
    } else {
      this.contextualRight = null;
    }
  }

  /**
   * Handle a click on a contextual navigation menu button
   * @param item 
   */
  public contextualClick(item: any) {
    this.router.navigate([item.path])
  }

  /**
   * Navigate to home scene when clicking on the logo
   * @param $event 
   */
   public logoClick($event: any) {
    $event.preventDefault();
    this.showWelcome = true;
    this.drawerDesktopShow = false;
    this.drawerMobileShow = false;
    this.router.navigate([""]);
  }

  /**
   * Navigate to main lobby
   * @param $event 
   */
   public enterClick($event: any) {
    $event.preventDefault();
    this.showWelcome = false;
    this.router.navigate(["welcome-lobby"]);
  }

  public drawerDesktopToggle() {
    this.drawerDesktopShow = !this.drawerDesktopShow;
  }

  public drawerMobileToggle() {
    this.drawerMobileShow = !this.drawerMobileShow;
  }

  /**
   * Click on contact us button 
   */
  public contactClick($event: any) {
    $event.preventDefault();
    location.href = "mailto:BASFAutomotiveSolutions@basf.com";
  }

  /**
   * Navigation item click
   * - Show any related photosphere
   * @param navId 
   */
  public navClick(navId: string) {
    const navItem = this.findNavItem(navId);
    if (navItem) {
      this.showWelcome = false;
      this.drawerDesktopShow = false;
      this.drawerMobileShow = false;
      this.router.navigate([navItem.path]);
    }
  }

  /**
   * Navigation submenu click
   * - Toggle submenu visibility
   * @param navId 
   */
  public subNavClick(navId: string) {
    const navItem = this.navItems.find((item: any) => item.id === navId);
    if (navItem && navItem.id !== this.subNavExpanded) {
      this.subNavExpanded = navItem.id;
    } else {
      this.subNavExpanded = "";
    }
  }

  /**
   * Callback for hiding menus when clicking outside the root menu element
   * @param event 
   */
  public checkOutsideClick(event: any) {
    const target: any = event?.target ? event.target : null;
    if (target && target["closest"] && !target.closest(".menu-interface")) {
      this.drawerDesktopShow = false;
      this.drawerMobileShow = false;
    }
  }

  /**
   * Go back to Innovation Center
   * @param event 
   */
  public backClick(event: any) {
    this.router.navigate(["innovation-center"]);
  }

}