/**
 * Utility / general services
 */

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public isMobile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // So uh yeah ... HoudiniPhotospheresService.currentPhotosphere.next() is only triggering the first time for subscribers in sub-components like navigation.component
  // So using this BehaviorSubject to re-trigger from app.component ¯\_(ツ)_/¯
  // @todo Probably missing a declaration in app.module 
  public currentPhotosphere: BehaviorSubject<any> = new BehaviorSubject(null);

  // Listen for scene/photosphere data changes
  public dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // Manually set background image
  public backgroundImageSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  // Drawer menu navigation was clicked?
  public navClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { 
    this.detectIsMobile();
    window.addEventListener("resize", ($event) => {
      this.detectIsMobile();
    });
  }

  detectIsMobile() {
    const isMobile: boolean = window.innerWidth <= 992;
    this.isMobile.next(isMobile);
  }
  
}
