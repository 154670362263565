<div [class]="['map', selectedName].join(' ')" [ngClass]="{ desktop: displayType === 'desktop', mobile: displayType === 'mobile' }">
    <div class="bg" *ngIf="displayType === 'mobile'">
        <img class="img" alt="Map background image" src="../../../assets/map/mobile-bg.svg">
    </div>
    <div class="bg" *ngIf="displayType === 'desktop'">
        <svg xmlns="http://www.w3.org/2000/svg" width="300" height="173.295" viewBox="0 0 300 173.295">
            <defs>
                <clipPath id="basf_map_clip">
                    <path d="M-338.6,2282.971l.511-6.646a198.173,198.173,0,0,1-43.628-6.242,51.55,51.55,0,0,1,1.546-12.014l-12.7-4.155a64.36,64.36,0,0,0-2.017,12.116,51.184,51.184,0,0,1-33.9,12.768,51.389,51.389,0,0,1-51.388-51.388,51.389,51.389,0,0,1,51.388-51.388A51.387,51.387,0,0,1-377.4,2226.2a64.681,64.681,0,0,0-8.5,11.238l11.86,6.151a51.488,51.488,0,0,1,36.07-23.85l-1.372-13.824a64.1,64.1,0,0,0-12.315,2.99,51.392,51.392,0,0,1-29.89-46.685,51.389,51.389,0,0,1,51.388-51.388,51.389,51.389,0,0,1,51.388,51.388,51.394,51.394,0,0,1-29.962,46.719,64.108,64.108,0,0,0-12.245-3l-1.181,13.857a51.494,51.494,0,0,1,35.684,23.749l11.924-6.045a64.6,64.6,0,0,0-8.373-11.122,51.388,51.388,0,0,1,51.376-50.356,51.389,51.389,0,0,1,51.388,51.388,51.389,51.389,0,0,1-51.388,51.388,51.189,51.189,0,0,1-34.074-12.926,64.366,64.366,0,0,0-1.991-11.9l-12.728,4.035a51.555,51.555,0,0,1,1.562,12.118,198.439,198.439,0,0,1-43.627,6.2l.51,6.646q-4.092.151-8.266.153Q-334.421,2283.129-338.6,2282.971Z" transform="translate(480.164 -2110.022)" fill="#fff"/>
                </clipPath>
            </defs>
        </svg>
    </div>
    <div class="outline">
        <img class="img" alt="Map outline" src="../../../assets/map/outline-fs8.png">
    </div>
    <div class="dot" [ngStyle]="currentTransform ? { transform: currentTransform, opacity: 1 } : null"></div>
    <div class="click">
        <img class="img" [attr.usemap]="'#basf-image-map-' + displayType" alt="Map clickable area" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACtCAQAAACg74o3AAABQ0lEQVR42u3SMQ0AAAzDsJU/6aHoUcmGECUHBZEAY2EsjAXGwlgYC4yFsTAWGAtjYSwwFsbCWGAsjIWxwFgYC2OBsTAWxgJjYSyMBcbCWBgLjIWxMBYYC2NhLDAWxsJYYCyMhbHAWBgLY4GxMBbGAmNhLIwFxsJYGAuMhbEwFhgLY2EsMBbGwlhgLIyFscBYGAtjgbEwFsYCY2EsjAXGwlgYC4yFsTAWGAtjYSwwFsbCWBgLjIWxMBYYC2NhLDAWxsJYYCyMhbHAWBgLY4GxMBbGAmNhLIwFxsJYGAuMhbEwFhgLY2EsMBbGwlhgLIyFscBYGAtjgbEwFsYCY2EsjAXGwlgYC4yFsTAWGAtjYSwwFsbCWGAsjIWxwFgYC2OBsTAWxgJjYSyMBcbCWBgLjIWxMBYYC2NhLDAWxsJYYCyMxZAHpN0ArgQ3/hYAAAAASUVORK5CYII=">
    </div>
</div>

<map [name]="'basf-image-map-' + displayType">
    <area *ngFor="let coord of mapCoords" (click)="onClick($event, coord)" [alt]="coord.title" [href]="coord.url" [coords]="coord.coords" [shape]="coord.shape">
</map>