<!-- Purposefully commenting out the router-outlet; otherwise getting double <houdini-photospheres>; possibly need to use a named router-outlet -->
<!-- <router-outlet></router-outlet> -->

<houdini-photospheres
  coverImage="../assets/images/basf-logo.png"
  loadingImage="../assets/images/basf-logo.png"
  [backgroundImageSubject]="backgroundImageSubject"
  [disablePhotosphereRotate]="false"
  (hotspotClicked)="onHotspotClick($event)"
></houdini-photospheres>

<!--
<houdini-photospheres
  [fetchDataObservable]="photospheresDataObservable"
  coverImage="../assets/images/basf-logo.png"
  loadingImage="../assets/images/basf-logo.png"
  [backgroundImageSubject]="backgroundImageSubject"
  [disablePhotosphereRotate]="false"
  (hotspotClicked)="onHotspotClick($event)"
  (hotspotEnter)="onHotspotEnter($event)"
  (hotspotLeave)="onHotspotLeave($event)"
></houdini-photospheres>
-->

<app-navigation></app-navigation>

<app-carousel></app-carousel>