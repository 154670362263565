<div class="logo" [ngClass]="{ smaller: smallerLogo }">
    <div class="inner" (click)="logoClick($event)">
        <img src="../../../assets/images/basf-logo.png" alt="BASF - We create chemistry">
    </div>
</div>

<div class="menu-interface menu-desktop" [ngClass]="{ on: !showWelcome && !isMobile, 'show-back': showBack }">
    <div class="title-bar">
        <button type="button" class="menu-button back" (click)="backClick($event)">
            <span class="icon"></span>
            <span class="label">Back to Innovation Center</span>
        </button>
        <div class="bar">
            <button type="button" class="menu-button" (click)="drawerDesktopToggle()">
                <span class="icon"><span class="line line1"></span><span class="line line2"></span><span class="line line3"></span></span>
                <span class="label">Menu</span>
            </button>
            <div class="nav-title">{{navTitle}}</div>
        </div>
    </div>
    <div class="drawer" [ngClass]="{ on: drawerDesktopShow }">
        <ul class="drawer-menu-items">
            <li *ngFor="let item of navItems"><button type="button" (click)="navClick(item.id)" [ngClass]="{ active: item.id === navId }">{{item.title}}</button></li>
        </ul>
        <div class="nav-contact">
            <button type="button" class="button contact" (click)="contactClick($event)">Contact Support</button>
        </div>
    </div>
    <div class="contextual left">
        <button type="button" class="reveal-button left" *ngIf="contextualLeft" (click)="contextualClick(contextualLeft)">
            <span class="label"><span></span>{{contextualLeft.title}}</span>
        </button>
    </div>
    <div class="contextual right" *ngIf="contextualRight">
        <button type="button" class="reveal-button right" (click)="contextualClick(contextualRight)">
            <span class="label">{{contextualRight.title}}<span></span></span>
        </button>
    </div>
</div>

<div class="menu-interface menu-mobile" [ngClass]="{ on: isMobile }">
    <div class="title-bar">
        <button type="button" class="menu-button" (click)="drawerMobileToggle()">
            <span class="icon"><span class="line line1"></span><span class="line line2"></span><span class="line line3"></span></span>
        </button>
    </div>
    <div class="drawer" [ngClass]="{ on: drawerMobileShow }">
        <div class="inner">
            <div class="nav-header">
                <button type="button" (click)="drawerMobileToggle()" class="drawer-close-button" aria-label="Close mobile drawer menu"></button>
            </div>
            <ul class="drawer-menu-items type-submenu">
                <li *ngFor="let item of navItems">
                    <button type="button" (click)="item?.submenu ? subNavClick(item.id) : navClick(item.id)" [ngClass]="{ active: item.id === navId, 'icon-down': item?.submenu, expanded: item?.submenu && item.id === subNavExpanded }">{{item.title}}</button>
                    <ul class="submenu" [ngClass]="{ expanded: item.id === subNavExpanded }" *ngIf="item?.submenu">
                        <li *ngFor="let subitem of item.submenu">
                            <button type="button" (click)="navClick(subitem.id)" [ngClass]="{ active: subitem.id === navId }">{{subitem.title}}</button>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="nav-map">
                <app-map displayType="mobile"></app-map>
            </div>
            <div class="nav-contact">
                <button type="button" class="button contact" (click)="contactClick($event)">Contact Support</button>
            </div>
        </div>
    </div>
</div>

<app-map displayType="desktop" *ngIf="!showWelcome && !isMobile"></app-map>

<div class="welcome" *ngIf="showWelcome">
    <div class="inner">
        <div class="topline">Welcome to</div>
        <div class="title">The Automotive Experience Center</div>
        <div class="text">Welcome to the virtual showroom for BASF automotive solutions! Learn how we can drive forward the future of mobility. Together.</div>
        <div class="buttons">
            <button type="button" class="button arrow-right" (click)="enterClick($event)">Enter</button>
            <!-- <button type="button" class="button arrow-left">Back To Lobby</button> -->
        </div>
    </div>
</div>