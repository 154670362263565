import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { HoudiniPhotospheresModule } from 'houdini-photospheres/dist/houdini-photospheres';
import { HoudiniPhotospheresModule } from 'houdini-photospheres';
import { NavigationComponent } from './components/navigation/navigation.component';
import { UtilsService } from './services/utils.service';
import { MapComponent } from './components/map/map.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { BasfModalGallery } from './modals/gallery/gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    MapComponent,
    CarouselComponent,
    BasfModalGallery
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HoudiniPhotospheresModule
  ],
  providers: [
    UtilsService,
    { provide: Window, useValue: window },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
