import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
//import { HoudiniPhotospheresService, HoudiniModalsService, Photosphere, ProjectData } from "houdini-photospheres/dist/houdini-photospheres";
import { HoudiniPhotospheresService, HoudiniModalsService, Photosphere, ProjectData } from "houdini-photospheres";
import { Router, NavigationEnd } from '@angular/router';
import { UtilsService } from './services/utils.service';
import { BasfModalGallery } from './modals/gallery/gallery.component';

declare const window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'BASF Virtual Showroom';

  // Display order of Innovation Center photospheres to cycle through
  public icOrder: any = [
    "innovation-center",
    "ic-preload-sustainability",
    "ic-preload-auto",
    "ic-preload-emobility",
    "ic-preload-safety"
  ];

  // Current index of displayed Innovation Center photosphere
  public icIndex: number = 0;

  // Manually set background image
  public backgroundImageSubject: BehaviorSubject<any>;

  // Previous background image
  public currentBgImg: any = "";

  // Passed along to Houdini Photospheres component for loading scene data
  public photospheresDataObservable!: Observable<any>;

  // Skip executing the next mouseleave event for a hotspot
  // Prevents background image swap confusion
  public skipNextLeaveEvent: boolean = false;

  // Key to bypass restricted access for meeting room
  // http://localhost:4200/meeting-room?key=1e617967ffe4cef23e1e3aa6c61a2cec
  // localStorage.setItem("basf-access-key", "1e617967ffe4cef23e1e3aa6c61a2cec");
  public restrictedBypassKey: string = "1e617967ffe4cef23e1e3aa6c61a2cec";

  // Enable bypass key for meeting room
  public enableBypassKey: boolean = true;

  // Key match detected
  public keyMatched: boolean = false;

  // Remember if event center welcome message 
  private welcomesShown: any = {
    "event-center": false,
    "innovation-center": false
  };
  public welcomed(id: string, val: boolean|null = null): boolean {
    if (val === null) {
      if (this.welcomesShown[id]) {
        return true;
      } else {
        return false;
      }
    } else {
      this.welcomesShown[id] = val;
      return this.welcomed(id);
    }
  }

  //
  constructor (
    private photosphereService: HoudiniPhotospheresService,
    private modalsSvc: HoudiniModalsService,
    private router: Router,
    private utilsSvc: UtilsService
  ) {
    this.backgroundImageSubject = utilsSvc.backgroundImageSubject;
    // Expose current photosphere to other components in this project
    // Also add the current photosphere as a class to the <body> element
    this.photosphereService.currentPhotosphere.subscribe((data: Photosphere|null) => {
      this.utilsSvc.currentPhotosphere.next(data);
      this.setBodyClass(data);
    });
    // Expose scene/photosphere/modal/router data to other components in this project
    this.photosphereService.dataSubject.subscribe((data) => {
      this.utilsSvc.dataSubject.next(data);
      //this.setBodyClass(data);
    });
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {

    //   }
    // });
    // - Show restricted area modal when loading meeting room photospheres
    // - Show popup welcome messages for Event Center and Innovation Center
    this.photosphereService.currentPhotosphere.subscribe((data) => {
      if (data) {
        // localStorage.setItem("basf-skip-welcomes", "true");
        if (localStorage.getItem("basf-skip-welcomes") !== "true") {
          if (!this.checkAccessKey() && data?.id === "meeting-room") {
            this.router.navigate(["/meeting-room/restricted-access"]);
          }
          if (!this.welcomed("event-center") && data?.id === "event-center") {
            this.welcomed("event-center", true);
            this.router.navigate(["/event-center/welcome"]);
          }
          if (!this.welcomed("innovation-center") && data?.id === "innovation-center") {
            this.welcomed("innovation-center", true);
            this.router.navigate(["/innovation-center/welcome"]);
          }
          if (!this.welcomed("lobby-welcome") && data?.id === "welcome-lobby") {
            this.welcomed("lobby-welcome", true);
            this.router.navigate(["/welcome-lobby/welcome"]);
          }
        }
      }
    });
    // Check if current photosphere can be rotated / is interactable 
    this.photosphereService.currentPhotosphere.subscribe((data) => {
      this.checkPhotosphereInteractable();
    });
    this.utilsSvc.isMobile.subscribe((data) =>  {
      this.checkPhotosphereInteractable(data);
    });
    // Restricted area: listen for go back / modal close clicks
    // Gallery heading on mobile: close the modal
    this.modalsSvc.modalContentClick.subscribe((data) => {
      if (data && data?.srcElement) {
        if (data?.srcElement.classList.contains("go-back")) {
          this.modalsSvc.hideModal();
        }
        if (window.innerWidth <= 992 && (data?.srcElement.classList.contains("close-gallery") || data?.srcElement.closest(".close-gallery"))) {
          this.modalsSvc.hideModal();
        }
      }
    });
    this.modalsSvc.closedModalData.subscribe((data) => {
      if (!this.keyMatched && data && data?.id === "restricted-access") {
        this.router.navigate(["welcome-lobby"]);
      }
    });
  }

  ngOnInit(): void {
    this.registerModalComponents();
    this.loadData();
  }

  /**
   * Register custom modal components for BASF
   */
  public registerModalComponents() {
    this.modalsSvc.registerModalComponent({
        id: "basf-gallery",
        title: "BASF Gallery",
        component: BasfModalGallery
    });
  }

  public loadData(): void {
    //this.photosphereService.loadProject("basf-stage", "/assets/data/data.json");
    this.photosphereService.loadProject("basf-stage");
    //this.photospheresDataObservable = this.photosphereService.getJson("/assets/data/data.json");
  }

  /**
   * Check for any access key, and set `keyMatched`
   * Also returns true/false depending on access key match state 
   * - Look in `window.location_on_load` global
   * - Look for localStorage flag `basf-access-key`
   */
  public checkAccessKey(): boolean {
    // Always return true when access key is disabled
    if (!this.enableBypassKey) {
      return true;
    }
    // localStorage
    if (localStorage.getItem("basf-access-key") === this.restrictedBypassKey) {
      this.keyMatched = true;
    }
    // Look in location on load
    if (!this.keyMatched) {
      const location_on_load: any = window?.location_on_load;
      if (location_on_load) {
        const params: URLSearchParams = (new URL(location_on_load.href)).searchParams;
        this.keyMatched = params.get("key") === this.restrictedBypassKey;
        // @todo
        // Remember the access key? 
        // if (this.keyMatched) {
        //   localStorage.setItem("basf-access-key", this.restrictedBypassKey);
        // }
      }
    }
    return this.keyMatched;
  }

  /**
   * Bind additional functionality to hotspot click events 
   * @param $event 
   */
  public onHotspotClick($event: any) {
    if ($event?.hotspot && $event.hotspot.id) {
      switch ($event.hotspot.id) {
        // // Innovation Center - center hotspot - cancel hotspot event and swap background image
        // case "ic.center":
        //   $event.canceled = true;
        //   if (this.photosphereService.photospheres) {
        //     this.icIndex++;
        //     if (this.icIndex >= this.icOrder.length) {
        //       this.icIndex = 0;
        //     }
        //     const psid = this.icOrder[this.icIndex];
        //     const ps = this.photosphereService.photospheres.find((ps) => { return ps?.id === psid });
        //     if (ps) {
        //       this.backgroundImageSubject.next(ps.backgroundImage);
        //       this.currentBgImg = ps.backgroundImage;
        //     }
        //   }
        // break;
        // Innovation Center sub-scenes - navigate into each 
        case "ic.sustainability":
        case "ic.auto":
        case "ic.emobility":
        case "ic.safety":
          //$event.canceled = true;
          this.skipNextLeaveEvent = true;
          //this.router.navigate([$event.hotspot.href]);
        break;
        // Do nothing, retain default behavior. 
        default:
      }
    }
  }

  /**
   * Hotspot hover: update photosphere image
   * @param $event 
   */
  public onHotspotEnter($event: any) {
    if (!this.utilsSvc.isMobile.getValue()) {
      // if (this.photosphereService.photospheres && $event?.hotspot && $event.hotspot?.extra) {
      //   const ps = this.photosphereService.photospheres.find((ps) => { return ps?.id === $event.hotspot.extra.swapPhotosphsere });
      //   if (ps) {
      //     this.backgroundImageSubject.next(ps.backgroundImage);
      //   }
      // }
      if ($event?.hotspot && $event.hotspot?.extra && $event.hotspot.extra?.overlayPhotosphere) {
        this.photosphereService.updateOverlayPhotosphere({
          id: $event.hotspot.extra.overlayPhotosphere,
          opacity: 1,
          animate: true,
          duration: 0.35
        });
      }
    }
  }

  /**
   * Hotspot stop hover: revert to photosphere image
   * @param $event 
   */
  public onHotspotLeave($event: any) {
    if (!this.utilsSvc.isMobile.getValue()) {
      if (this.skipNextLeaveEvent) {
        this.skipNextLeaveEvent = false;
      } else {
        // if (this.photosphereService.photospheres && $event?.hotspot && $event.hotspot?.extra) {
        //   const ps = this.photosphereService.photospheres.find((ps) => { return ps?.id === "innovation-center"});
        //   if (ps) {
        //     this.backgroundImageSubject.next("");  // Revert to default bg image
        //   }
        // }
        if ($event?.hotspot && $event.hotspot?.extra && $event.hotspot.extra?.overlayPhotosphere) {
          this.photosphereService.updateOverlayPhotosphere({
            id: $event.hotspot.extra.overlayPhotosphere,
            opacity: 0,
            animate: true,
            duration: 0.35
          });
        }
      }
    }
  }

  public setBodyClass(photosphere: Photosphere|null) {
    let toRemove = "";
    document.body.classList.forEach((className: string) => {
      if (!toRemove && className.indexOf("ps-") === 0) {
        toRemove = className;
      }
    });
    if (toRemove) {
      document.body.classList.remove(toRemove);
    }
    if (photosphere?.id) {
      document.body.classList.add("ps-" + photosphere.id);
    }
  }

  public checkPhotosphereInteractable(isMobile: any = null): void {
    const photosphere = this.photosphereService.currentPhotosphere.getValue();
    if (photosphere && photosphere?.id === "event-center") {
      setTimeout(() => {
        if (isMobile === null) {
          isMobile = !!this.utilsSvc.isMobile.getValue();
        }
        this.photosphereService.setInteractable(!isMobile);
      }, 500);
    } else {
      this.photosphereService.setInteractable(true);
    }
  }

}
