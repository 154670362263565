import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
// @ts-ignore
//import { ImageMap } from 'image-map'; // Responsive ImageMap is not TS. Might not be needed. 

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  // Type of map display: desktop or mobile
  @Input() displayType: string = "desktop";

  // Which area has been selected
  public selectedName: string = "welcome-lobby";

  // Current CSS transform to apply to dot
  public currentTransform: string = "";

  // Map coordinates and shapes
  public mapCoords: any = [
    {
      "name": "event-center",
      "url": "/event-center",
      "title": "Event Center",
      "transform": "translate(41px, 135px)",
      "shape": "circle",
      "coords": "51,118,50"
    },
    {
      "name": "meeting-room",
      "url": "/meeting-room",
      "title": "Meeting Room",
      "transform": "translate(138px, 66px)",
      "shape": "circle",
      "coords": "150,51,50"
    },
    {
      "name": "innovation-center",
      "url": "/innovation-center",
      "title": "Innovation Center",
      "transform": "translate(237px, 132px)",
      "shape": "circle",
      "coords": "250,117,52"
    },
    {
      "name": "welcome-lobby",
      "url": "/welcome-lobby",
      "title": "Welcome Lobby",
      "transform": "translate(138px, 121px)",
      "shape": "poly",
      "coords": "98,158,110,127,126,114,142,110,159,111,180,119,193,134,200,147,198,161,150,173"
    },
  ];
  

  constructor(
    private router: Router,
    private utilsSvc: UtilsService
  ) { }

  ngOnInit(): void {
    // Set and animate dot
    this.setTransform(this.selectedName);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        for (let coord of this.mapCoords) {
          // Match path and sub-paths
          if (event.url.indexOf(coord.url) === 0) {
            this.selectedName = coord.name;
            this.setTransform(this.selectedName);
            break;
          }
        }
      }
    });
  }

  public onClick($event: any, coord: any) {
    $event.preventDefault();
    if (coord?.name && coord?.url) {
      this.selectedName = coord?.name;
      this.setTransform(this.selectedName);
      // Inform navigation component about nav clicks
      this.utilsSvc.navClicked.next(true);
      // Navigate to URL
      this.router.navigate([coord?.url]);
    }
  }

  public setTransform(name: string) {
    const coord = this.mapCoords.find((coord: any) => coord.name === name);
    this.currentTransform = coord.transform;
  }

}
